<template>
	<div class="standard">
		<div class="container">
			<div class="standard-box flex mt-2">
				<div class="standard-box-l">
					<div class="standard-swiper">
						
						<div class="swiper-container banner-container" style="height: 100%;">
							<div class="swiper-wrapper">
								<template v-for="(item,index) in bannerList" >
									<div :key="index" class="swiper-slide bg-cent" :style="{backgroundImage: 'url(' + item.img_url_temp + ')'  }">
									</div> 
								</template>
							</div>
							 <div class="swiper-pagination"></div>
							 <div class="swiper-button-prev"></div>
							 <div class="swiper-button-next"></div>
						</div>
						
					</div>
					<div class="standard-menu flex">
						
						<div class="standard-menu-list cursor-pointer" v-for="(item,index) in menuList" :key='index' @click="pickerMenu(item.id,index)">
							<h1 :class="categoryId == item.id ? 'h-color' : '' ">{{item.name}}</h1>
						</div>
						
					</div>
					
					<div style="min-height: 60vh;">
						<template v-for="(item, index) in articleList">
							<datalis :item='item' :key='index'></datalis>	
						</template>
						
						<template v-if="articleList.length == 0">
							<div class="no-case-list py-5">
								<img src="@/assets/image/noDate.png" />
							</div>
						</template>
					</div>
					
					<div class="policy-page my-5 flex justify-center">
						<a-pagination @change="selectPage"  :current="page" :pageSize="limit" :total="total" />
					</div>
					
				</div>
				<div class="standard-box-r">
					<div class="standard-box-serve">
						<div class="mb-1">
							<h1>服务范围</h1>
						</div>
						
						<div class="standard-box-serve-box">
							<div class="standard-box-serve-box-list cursor-pointer" @click="$toConnectLink" v-for="(item,index) in severList" :key='index'>
								{{item}}
							</div>
						</div>
					</div>
					
					<div class="article">
						<h1>热门文章</h1>
						<div class="article-box">
							<div class="article-box-list flex align-center" v-for="(item,index) in recommendList" :key="index">
								<span class="flex"></span>
								<p class="test-hide test w-100">{{item.title}}</p>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from "swiper";
	import datalis from '@/components/common/data-list.vue'
	export default {
		components:{
			datalis
		},
		data() {
			return {
				severList:['资质新办','资质整转','资质延期','资质升级'],
				menuList:[],
				page:1,
				limit:10,
				total:0,
				articleList: [],
				categoryId:this.$route.query.categoryId || '',
				recommendList:[],
				bannerList:[]
			}
		},
		methods: {
			getBannerList(){
				this.$api.getBannerList({
						subdoadmin	: '',
						launchPage	: 13,
						launchFacility : 10
					})
					.then((res) => {
						this.bannerList = res.data.list;
					});
			},
			getCategoryChild(pid){
				this.$api.getCategoryChild({
						subdoadmin:'',
						pid: pid
					})
					.then((res) => {
						this.menuList = res.data;
					});
			},
			_init_swiper() {
				var swiper = new Swiper(".banner-container", {
					autoplay: 2000,
					loop: false,
					pagination : '.swiper-pagination',
					prevButton:'.swiper-button-prev',
					nextButton:'.swiper-button-next',
				});
			},
			// 点击menu 
			pickerMenu(id,index){
				this.categoryId = id ;
				this.page = 1;
				this.getArticleList();
			},
			selectPage(e){
				this.page = e;
				this.getArticleList();
			},
			getArticleList() {
				this.$api
					.getArticleList({
						flag : 'criterion',
						page: this.page,
						limit: this.limit,
						search: this.search,
						categoryId:this.categoryId
					})
					.then((res) => {
						this.articleList = res.data.list;
						this.total = res.data.total;
					});
			},
			// 推荐
			getRecommend() {
				this.$api
					.getArticleList({
						flag : 'recommend',
						page: 1,
						limit: 10,
					})
					.then((res) => {
						this.recommendList = res.data.list;
						// this.total = res.data.total;
					});
			},
		},
		mounted() {
			this.getRecommend();
			this.getBannerList();
			this.getCategoryChild(141);
			this._init_swiper();
		},
		created() {
			this.getArticleList();
		}
	}
</script>

<style lang="scss" scoped="scoped">
	/deep/ .swiper-pagination-bullet{
		width: 12px;
		height: 12px;
		background: #FFFFFF;
		border-radius: 50%;
	}
	.swiper-button-next, .swiper-button-prev{
		background-color: rgba(0,0,0,0.2);
		padding: 40px 20px;
	}
	.no-case-list {
	    width: 100%;
	    height: 60vh;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	
	    img {
	        width: 200px;
	    }
	}
	.standard {
		&-box {
			&-l {
				width: 860px;
				margin-right: 20px;
				.standard-swiper {
					width: 860px;
					height: 340px;
				}
				.standard-menu {
					margin-top: 25px;
					margin-bottom: 10px;
					height: 55px;
					justify-content: space-around;
					background: #FFFFFF;
					font-size: 20px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					line-height: 55px;
					&-list {
						h1 {
							color: #AFB0B7;
							border-bottom: 2px solid transparent;
							transition: all 0.6s;
						}

						.h-color {
							color: #2070FF;
							border-bottom: 2px solid #2070FF;
						}
					}
				}
			}
			&-r{
				width: 100%;
				.standard-box-serve{
					background: #FFFFFF;
					padding: 30px 20px;
					h1{
						font-size: 18px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #727585;
						margin-bottom: 10px;
					}
					.standard-box-serve-box{
						padding: 0 15px;
						.standard-box-serve-box-list{
							height: 55px;
							border: 1px solid #E2E2E2;
							margin-top: 28px;
							line-height: 55px;
							text-align: center;
							font-size: 18px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #7B7C84;
							transition: all 0.6s;
							&:hover{
								border: 1px solid #2070FF;
								color: #2070FF;
							}
						}
					}
				}
				.article{
					width: 295px;
					margin-top: 15px;
					background: #FFFFFF;
					padding: 30px 20px;
					h1{
						font-size: 18px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #727585;
						margin-bottom: 10px;
					}
					.article-box{
						.article-box-list{
							&:hover .test{
								color: #2070FF;
							}
							cursor: pointer;
							margin-top: 25px;
							span{
								display: block;
								width: 6px;
								height: 6px;
								background: #2070FF;
								border-radius: 50%;
								margin-right: 20px;
							}
							.test{
								font-size: 18px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #7B7C84;
							}
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: 1024px) {}
	
	@media only screen and (max-width: 750px) {}
	
	@media only screen and (max-width: 481px) {
		.standard{
			background-color: #FFFFFF !important;
		}
		.standard{
			.standard-box{
				.standard-box-l{
					width: 100%;
					margin-right: 0px;
					.standard-menu-list h1{
						font-size: 13px;
					}
					.standard-swiper{
						width: 100%;
						height: 148px;
					}
					.swiper-button-disabled{
						display: none;
					}
					.standard-menu{
						border-bottom: 1px solid #F5F8FA;
					}
				}
				.standard-box-r{
					display:none;
				}
			}
		}
	}
</style>
